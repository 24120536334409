import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { MyRoutes } from "./Routes";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MyRoutes />
  </React.StrictMode>,
);
function sendToGoogleAnalytics({ id, name, value, delta }) {
  // Assumes the global `gtag()` function exists, see: https://developers.google.com/analytics/devguides/collection/gtagjs
  // window.gtag("event", name, {
  //   event_category: "Web Vitals",
  //   value: Math.round(name === "CLS" ? delta * 1000 : delta), // ensure values are in milliseconds
  //   event_label: "G-3WJ3LQFWH8", // use the id as the event label
  //   non_interaction: true, // mark as non-interactive to avoid affecting bounce rate
  // });
}
reportWebVitals(sendToGoogleAnalytics);
