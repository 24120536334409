import { ReactComponent as Logo } from "./logo.svg";

export function Banner({}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        alignItems: "center",
        justifyContent: "start",
        padding: 10,
      }}
    >
      <Logo
        style={{
          height: 30,
        }}
      />

      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Make My Brand
      </div>
    </div>
  );
}
