import { Button,  Link, Menu, MenuItem} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import EditProfileDialog from "./EditProfileDialog";
import { req } from "./ServerUtils";
import { HelpDialog } from "./HelpDialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export function NavBar({generate, download}) {
  const [userInfo, setUserInfo] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Returns true for small screens
  
  useEffect(() => {
    req("/user_info").then((res) => {
      setUserInfo(res);
    });
  }, [])
  
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 5,
        justifyContent: "space-between",
        alignItems: "center",
        padding: 8,

        background: "linear-gradient(33deg, rgb(46 118 61) 30%, rgb(41 158 52) 60%)",
        color: "white",
      }}
    >
      <div></div>
      
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 15,
          justifyContent: "space-between",
          alignItems: "center",

          background: "transparent",
        }}
      >
        <Generate generate={generate}/>
        <Download download={download}/>

        <ProfilePic userInfo={userInfo} setUserInfo={setUserInfo} fullScreen={fullScreen}/>

        {!userInfo && (
          <Link
            underline="none"
            variant="button"
            href={'/login'}
            style={{
              textTransform: "none",
              color: "white",
            }}
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
}

function ProfilePic({ userInfo, setUserInfo, fullScreen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(null);
  const fileRef = useRef(null);
  const [src, setSrc] = useState(userInfo?.profile_pic_cropped || "/images/profilepic.jpg");
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (!userInfo?.profile_pic_cropped) {
      setSrc("/images/profilepic.jpg");
      return;
    }
    fetch(userInfo?.profile_pic_cropped + "?t=" + new Date().getTime())
      .then((res) => {
        if (res.status === 404) {
          setSrc("/images/profilepic.jpg");
        } else {
          setSrc(userInfo?.profile_pic_cropped + "?t=" + new Date().getTime());
        }
      })
      .catch((e) => {
        setSrc("/images/profilepic.jpg");
        console.error(e);
      });
  }, [userInfo]);

  function verifyAndUpload(key, file, api) {
    if (!file.type.startsWith("image/")) {
      console.error("File is not an image.");
      return Promise.reject("File is not an image.");
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const formData = new FormData();
          formData.append(key, file);

          api(formData)
            .then((res) => {
              if (res.error) {
                reject(res.error);
              } else {
                resolve(res);
              }
            })
            .catch((e) => reject(e));
        };
        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  function onFileUpload(e) {
    verifyAndUpload("profile_pic_uncropped", e.target.files[0], (formData) => {
      return req("/upload_profile_pic", "POST", formData, false);
    })
      .then((res) => {
        userInfo.profile_pic_cropped = res.profile_pic_url;
        setUserInfo({ ...userInfo });
      })
      .catch((e) => {
        console.error(e);
      });
    fileRef.current.value = ""; // Without this, clicking it 2nd time doesn't work
  }

  function onCropComplete(blob) {
    const formData = new FormData();
    formData.append("profile_pic_cropped", blob, "cropped-image.png");
    req("/upload_profile_pic", "POST", formData, false)
      .then((res) => {
        userInfo.profile_pic_cropped = res.profile_pic_url;
        setUserInfo({ ...userInfo });
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      <div
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <img
          src={src}
          alt="Profile"
          style={{
            height: "30px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disablePortal={true}
      >
        <MenuItem
          onClick={() => {
            setShowEditProfileDialog("profile");
            setAnchorEl(null);
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowEditProfileDialog("tokens");
            setAnchorEl(null);
          }}
        >
          My Tokens
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShowHelp(true);
          }}
        >
          Help
        </MenuItem>
        <MenuItem
          onClick={() => {
            req("/logout", "POST", {}).then(() => {
              window.location.href = "/login";
            });
            setAnchorEl(null);
          }}
        >
          Logout
        </MenuItem>
      </Menu>

      <input style={{ display: "none" }} ref={fileRef} type="file" accept="image/*" onChange={(e) => onFileUpload(e)} />

      <EditProfileDialog fullScreen={fullScreen} userInfo={userInfo} setUserInfo={setUserInfo} dialog={showEditProfileDialog} onClose={() => setShowEditProfileDialog(false)} onSave={(data) => console.log(data)} user={userInfo} setDialog={setShowEditProfileDialog}/>
      <HelpDialog userInfo={userInfo} setUserInfo={setUserInfo} showHelp={showHelp} setShowHelp={setShowHelp} />
    </>
  );
}


export function Generate({generate}) {
  return (<Button style={{
    textTransform: 'none',
    marginBottom: 2,
    color: "white",
    borderColor: "white"
  }}
  variant="outlined"
    onClick={generate}
  >Generate Now</Button>)
}

function Download({download}) {
    return (<Button style={{
      textTransform: 'none',
      marginBottom: 2,
      borderColor: "white", 
      color: "white"
    }}
      variant="outlined"
      onClick={download}
    >Download</Button>)
  }
  