function replaceInSection(sectionDiv, data) {
    if (!sectionDiv) return;
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let value = data[key];
        if (key == "icon") {
            value = value['svg'];
        } else if (key == "image") {
            value = value['src']
        }

        sectionDiv.innerHTML = sectionDiv.innerHTML.replace(`{${key}}`, value);
        
    }
}
export function makeDesign(html, data) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const headerDiv = doc.querySelector('.mmb-header');
    replaceInSection(headerDiv, data['header']);

    const blocks = doc.querySelectorAll('.mmb-block');
    for (let i = 0; i < blocks.length; i++) {
        replaceInSection(blocks[i], data['items'][i]);
    }
    const footerDiv = doc.querySelector('.mmb-footer');
    replaceInSection(footerDiv, data['footer']);

    return doc.documentElement.outerHTML;
}
