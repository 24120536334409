// @ts-nocheck


// import commitInfo from "./commitInfo.json";

export function BottomBar() {
  const style = {
    display: "flex",
    justifyContent: "start",
    gap: "20px",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px 10px",
        fontSize: "0.7em",
        backgroundColor: "#eee",
      }}
    >
      <div className="BottomBar" style={style}>
        <a href="/terms">Terms</a>
        <a href="/privacy">Privacy</a>
        <a href="/contact">Contact</a>
      </div>
      {/* <div className="commit-info">{commitInfo?.lastCommitDate}</div> */}
    </div>
  );
}
