import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { req } from "./ServerUtils";
import { CircularProgress } from "@mui/material";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);

  const [loading, setLoading] = useState(true);


  function onFetch(res) {
    
    if (!res) {
      window.location.href = "/login";
      return;
    }
    if (["basic", "premium"].includes(res.subscription_type)) {
        setUserInfo(res);
        setLastFetched(Date.now());
    } else {
        window.location.href = "/choose-plan";
    }
    setLoading(false);
  }

  const fetchUserInfo = async () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("/login") || currentUrl.includes("/choose-plan")) {
      setLoading(false);
      return;
    }
    const now = Date.now();
    if (lastFetched && now - lastFetched < 5 * 60 * 1000) {
      if (userInfo) {
        onFetch(userInfo);
      }
      return;
    }

    setLoading(true);
    req('/user_info').then(res => {
        onFetch(res);
    }).catch(e => {
        window.location.href = "/login";
        setLoading(false);
    })

  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  if (loading) return <div>
    <CircularProgress /> 
  </div>;

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo, refetchUserInfo: fetchUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
