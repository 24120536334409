import React from "react";
import { Card, CardContent, CardHeader, Button, List, ListItem, ListItemText } from "@mui/material";
import { useUser } from "./UserProvider";

const plans = [
  {
    name: "Basic",
    price: "$14.99/month",
    features: [
        "100 infographics/month",
        "Access to Basic templates",
        "Unlimited Edits",
        "Unlimited Downloads",
        "Custom Branding",
        "Email Support",
    ],
    url: 'https://store.makemybrand.ai/buy/49278c51-9434-46da-88f7-546674748657?enabled=673112'
  },
  {
    name: "Premium",
    price: "$24.99/month",
    features: ["Everything in Basic Plan", "Access to Premium templates"],
    url: 'https://store.makemybrand.ai/buy/5c6cafdb-5b40-4627-bad7-991e9d9b3b28?enabled=673113'
  },
];

const ChoosePlan = () => {
    const {userInfo} = useUser();
    return (
        <div style={{

            padding: "2rem",
            maxWidth: 800,
            margin: "auto",
        }}>

            <div style={{
                marginBottom: 30,
                textAlign: "center",
            }}>
                Please purchase a plan to continue generating designs.
            </div>
        
            <div
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                
            }}
            >
            {plans.map((plan, index) => (
                <Card
                key={index}
                elevation={3}
                style={{
                    borderRadius: "12px",
                    textAlign: "center",
                    flex: "1",
                    marginLeft: index == 1 ? -30 : 0,
                    zIndex: index == 0 ? 1 : 0
                }}
                >
                <CardHeader
                    title={plan.name}
                    style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    backgroundColor: index == 0 ? "#1976d2" :"#f5f5f5",
                    padding: "1rem",
                    }}
                />
                <CardContent>
                    <div
                    style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                    }}
                    >
                    {plan.price}
                    </div>
                    <List>
                    {plan.features.map((feature, i) => (
                        <ListItem
                        key={i}
                        disableGutters
                        >
                        <ListItemText
                            primary={feature}
                            style={{
                            textAlign: "center",
                            }}
                        />
                        </ListItem>
                    ))}
                    </List>
                    <Button
                        variant={index == 0 ? "contained" : "outlined"}
                        style={{
                            marginTop: "1rem",
                            borderRadius: "20px",
                            padding: "0.5rem 2rem"
                        }}
                        onClick={() => {
                            let url = plan.url + "?";
                            if (userInfo?.email) {
                                url += `checkout[email]=${userInfo.email}`;
                            }
                            url += "&checkout[discount_code]=HAPPY2025";
                            window.location.href = url;
                        }}
                    >
                        Subscribe to {plan.name}
                    </Button>
                </CardContent>
                </Card>
            ))}
            </div>
            </div>
    );
};

export default ChoosePlan;
