import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  CircularProgress,
  Grid
} from '@mui/material';
import { req } from './ServerUtils';
import Gallery from './Gallery';

const TemplateSelectorModal = ({ isOpen, onClose, onTemplateSelect }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>

      <TemplateGallery onTemplateSelect={onTemplateSelect} isOpen={isOpen}/>
    </Modal>
  );
};

export function TemplateGallery({selectedTemplate, setSelectedTemplate, isOpen}) {
  const [templates, setTemplates] = useState([]);
  const [cachedTemplates, setCachedTemplates] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [] = useState(null);

  useEffect(() => {
    if (isOpen && !cachedTemplates[page]) fetchTemplates(page);
    else if (isOpen && cachedTemplates[page]) setTemplates(cachedTemplates[page]);
  }, [isOpen, page]);

  const fetchTemplates = async (page) => {
    setLoading(true);
    try {
      const data = await req(`/html_templates?page=${page}`);
      setTemplates(data.templates);
      setTotalPages(data.total_pages);
      setCachedTemplates((prev) => ({ ...prev, [page]: data.templates }));
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (direction) => {
    setPage((prev) => (direction === 'next' ? prev + 1 : prev - 1));
  };

  return <Box style={{
    height: "70vh",
    overflow: "scroll"
  }}>
  {loading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Gallery
          style={{
            overflowY: "scroll",
            width: "100%",
            maxWidth: "100%",
          }}
          data={templates}
          render={(template, itemWidth) => (
            <Box
              onClick={() => setSelectedTemplate(template)}
              sx={{
                border: selectedTemplate?.id === template.id ? '4px solid #8e918e' : '4px solid transparent',
                borderRadius: 2,
                overflow: 'hidden',
                cursor: 'pointer',
                textAlign: 'center'
              }}
            >

              <img src={template.preview} alt={template.title} style={{ width: itemWidth, height: 'auto' }} />
            </Box>)
          }
        />
        
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          disabled={page === 1}
          onClick={() => handlePageChange('prev')}
          variant="outlined"
        >
          Previous
        </Button>
        <Button
          disabled={page === totalPages}
          onClick={() => handlePageChange('next')}
          variant="outlined"
        >
          Next
        </Button>
      </Box>
    
    </>
  )}
</Box>
}

export default TemplateSelectorModal;
