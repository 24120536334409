import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { req } from './ServerUtils';
import DesignSettings from './DesignSettings';
import { NavBar } from './Navbar';
import { makeDesign } from './utils';

const code = `<script src="https://mmb-js.s3.us-east-1.amazonaws.com/dom-to-image.js"></script><script>
function screenshot() {
    domtoimage.toPng(document.body).then((dataUrl) => {
        var link = document.createElement('a');
        link.download = 'makemybrand-infographic.png';
        link.href = dataUrl;
        link.click();
    });
    
}

document.addEventListener('DOMContentLoaded', async function screenshotWrapper() {
    await screenshot();
    document.removeEventListener('DOMContentLoaded', screenshotWrapper);
});

</script>`;


const DesignEditor = () => {
    const [isTablet, setIsTablet] = useState(window.matchMedia('(max-width: 600px)').matches);

    const { urlId } = useParams();
    const designId = urlId || "cvcstxssin";
    const iframeRef = useRef(null);
    const [config, setConfig] = useState(null);
    const [iframeSize, setIframeSize] = useState({ width: 380, height: 800 });
    const [design, setDesign] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isInputModalOpen, setIsInputModalOpen] = useState(false);


    const generate = () => {
        setIsInputModalOpen(true);
    };

    const download = async () => {
        let htmlContent = makeDesign(design.pages[0].template_html, design.pages[0].data);
        htmlContent = htmlContent.replace('</head>', code + '</head>');

        const iframeDoc = iframeRef.current.contentDocument;
        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();
    };
    

    const handleToggle = (className) => {
        setConfig(prev => {
            const updatedConfig = {
                ...prev,
                [className]: { ...prev[className], visible: !prev[className].visible }
            };
            injectCSS(updatedConfig);
            return updatedConfig;
        });
    };

    const handlePropertyChange = (className, property, value) => {
        setConfig(prev => {
            const updatedConfig = {
                ...prev,
                [className]: { ...prev[className], [property]: value }
            };
            injectCSS(updatedConfig);
            return updatedConfig;
        });
    };

    const injectCSS = (updatedConfig) => {
        const iframeDoc = iframeRef.current.contentDocument;
        let styleTag = iframeDoc.getElementById('dynamic-styles');
        if (!styleTag) {
            styleTag = iframeDoc.createElement('style');
            styleTag.id = 'dynamic-styles';
            iframeDoc.head.appendChild(styleTag);
        }
        const css = Object.entries(updatedConfig)
            .map(([className, props]) => {
                const visibilityCSS = props.visible !== undefined
                    ? `display: ${props.visible ? 'block' : 'none'};`
                    : '';
                const dynamicStyles = Object.entries(props)
                    .filter(([key]) => key !== 'visible')
                    .map(([key, value]) => `${key}: ${value}px;`)
                    .join(' ');
                return `${className} { ${visibilityCSS} ${dynamicStyles} }`;
            })
            .join(' ');
        styleTag.innerHTML = css;
    };

    useEffect(() => {
        const loadHTML = async () => {
            req('/user_info').then((uinfo) => {
                setUserInfo(uinfo);
                if (!uinfo) {
                    window.location = '/login';
                    return
                }

                req(`/html_design?id=${designId}`).then((design) => {
                    setDesign(design);
                });
            }).catch(e=> {
                window.location = '/login';
            })
        };
        loadHTML();
    }, []);

    useEffect(() => {
        if (!design || !design.pages[0].template_html) return;

        let html = makeDesign(design.pages[0].template_html, design.pages[0].data);

        const iframeDoc = iframeRef.current.contentDocument;
        iframeDoc.open();
        iframeDoc.write(html);
        iframeDoc.close();
    }, [design])


    function onPresetChange(preset) {
        setIframeSize({ width: preset.width, height: preset.height })        
    }
    return (
        <div>
            <NavBar generate={generate} download={download}/>
            
            <div style={{
                display: 'flex',
                flexDirection: isTablet ? 'column' : 'row',
                justifyContent: 'start',
                alignItems: isTablet ? 'stretch' : 'start',

            }}>

                <iframe
                    ref={iframeRef}
                    style={{ 
                        width: isTablet ? "100%" : iframeSize.width, 
                        height: isTablet ? "auto" : iframeSize.height, 
                        border: '1px solid #ccc',
                        minHeight: isTablet ? "80vh" : "100vh",
                    }}
                />
                <DesignSettings design={design} setDesign={setDesign} onPresetChange={onPresetChange} config={config} handlePropertyChange={handlePropertyChange} handleToggle={handleToggle} download={download} isTablet={isTablet} isInputModalOpen={isInputModalOpen} 
                setIsInputModalOpen={setIsInputModalOpen}
                />
                
            </div>
        </div>
    );
};



export default DesignEditor;


