import { parseColor, shiftHues } from "./colorutils";

export function Colors({}) {
    return (
        <div>

        </div>
    )
}
function extractRootKeysFromIframe(iframe) {
    const styleBlocks = iframe.contentDocument.querySelectorAll('style');
    const keys = new Set();

    styleBlocks.forEach((style) => {
        const rootMatch = style.textContent.match(/:root\s*{([^}]*)}/);
        if (rootMatch) {
            const rootContent = rootMatch[1];
            const matches = rootContent.match(/--[\w-]+(?=\s*:)/g);
            if (matches) {
                matches.forEach((key) => keys.add(key.trim()));
            }
        }
    });

    return Array.from(keys);
}


function extractColors(html) {
    return new Promise((resolve,reject)=>{
            let iframe=document.createElement("iframe")
            iframe.style.display="none"
            document.body.appendChild(iframe)
            iframe.contentDocument.open()
            iframe.contentDocument.write(html)
            iframe.contentDocument.close()
            iframe.onload=()=>{
                try {
                    const colorKeys = extractRootKeysFromIframe(iframe).filter((key) => key.startsWith('--color'));
                    let colors = [], colorNames = [];
                    for(let v of colorKeys) {
                        if(v.startsWith('--color')) {
                            let color = iframe.contentWindow.getComputedStyle(iframe.contentDocument.documentElement).getPropertyValue(v);
                            colors.push(color);
                            colorNames.push(v);
                            console.log("found color", color)
                        }
                    }
                    // colors = Array.from(new Set(colors));
                    // delete this iframe
                    iframe.remove()
                    resolve({colors, colorNames})
                } catch (error) {
                    reject(error)
                }
            }
        });
}
function applyColors(html, oldColors, colorNames, newColors){
    for (let i = 0; i < oldColors.length; i++) {
        const key = `${colorNames[i]}: ` + oldColors[i] + ';';
        const newValue = `${colorNames[i]}: ` + newColors[i] + ';';
        html = html.replace(key, newValue);
    }
    return Promise.resolve(html);
}
export function randomizeColors(html) {
    return extractColors(html).then(({colors,colorNames}) => {
        const randomShift = (Math.random() * 360 + 20)%360;
        const newColors = shiftHues(colors, randomShift);
        return applyColors(html, colors, colorNames, newColors);
    });
}