import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MyRoutes } from "./Routes";
import { UserProvider } from './UserProvider';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#9c27b0", // Purple
    },
    secondary: {
      main: "#7b1fa2", // Darker purple
    },
    background: {
      default: "#f3e5f5", // Light purple background
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <MyRoutes />
      </UserProvider>    
    </ThemeProvider>
  </React.StrictMode>,
);
function sendToGoogleAnalytics({ id, name, value, delta }) {
  // Assumes the global `gtag()` function exists, see: https://developers.google.com/analytics/devguides/collection/gtagjs
  // window.gtag("event", name, {
  //   event_category: "Web Vitals",
  //   value: Math.round(name === "CLS" ? delta * 1000 : delta), // ensure values are in milliseconds
  //   event_label: "G-3WJ3LQFWH8", // use the id as the event label
  //   non_interaction: true, // mark as non-interactive to avoid affecting bounce rate
  // });
}
reportWebVitals(sendToGoogleAnalytics);
