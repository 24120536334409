import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, TextField } from "@mui/material";
import { useState } from "react";
import { req } from "./ServerUtils";

export function HelpDialog({ userInfo, showHelp, setShowHelp }) {

  const [email, setEmail] = useState(userInfo?.email);
  const [msg, setMsg] = useState("");
  if (!userInfo) return null;

  return (
    <Dialog open={showHelp} onClose={() => setShowHelp(false)}>
      <DialogTitle>Help</DialogTitle>
      <DialogContent
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
          justifyContent: "start",
          alignItems: "stretch",
        }}
      >
        <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Email" fullWidth />
        <TextareaAutosize style={{ width: "100%", height: 200 }} placeholder={`Please describe your issue here. Include as much detail as possible.`} value={msg} onChange={(e) => setMsg(e.target.value)} />
        <p>Alternatively, you can email support@makemybrand.ai with your queries.</p>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
          }}
          fullWidth
          onClick={() => {
            req("/help", "POST", JSON.stringify({ email, message: msg }));
            setShowHelp(false);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
