const BASE_URL = "https://api.makemybrand.ai";

export const poll = async ({task_id}) => {
  let pollCount = 0;
  return new Promise((resolve, reject) => {
      function pollInside() {
          pollCount++;
          if (pollCount > 20) {
              reject({error: 'Polling limit exceeded'});
              return;
          }
          req('/poll', 'POST', JSON.stringify({ task_id })).then(res => {
              const stat = res.status?.toLowerCase();
              if (stat !== 'failed' && stat !== 'success' && stat != "completed") { // todo you can remove completed
                  setTimeout(pollInside, 10000);
              } else if (stat == 'completed' || stat == "success") { // todo you can remove completed
                  resolve(res.result);
              } else {
                  reject(res.result);
              }
          }).catch(reject);
      }
      // setTimeout(() => pollInside, 10000);
      pollInside();
  });
}
export function reqPoll(path, method = "GET", bodyData, json = true) {
  return req(path, method, bodyData, json).then(poll);
}
export function req(path, method = "GET", bodyData, json = true) {
  const opts = {
    method,
    headers: {},
    credentials: "include",
  };
  if (window.location.hostname === "localhost") {
    opts.headers.authorization = "supersecrettoken";
  }
  if (bodyData) {
    opts.body = bodyData;
  }
  if (json) {
    opts.headers["Content-Type"] = "application/json";
  }
  const url = BASE_URL + path;
  return fetch(url, opts).then((response) => {
    return response.json().then((data) => {
      if (data.error) {
        throw new Error(data.error);
      }
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error(data.message);
      }
      return data;
    });
  });
}