import ImageWithUploader from "./ImageWithUploader";

export function ReplacableImages({design, setDesign}) {
    if (!design) return;

    return (
        design.pages.map((page, j) => (
           <div key={j}>
            <Section data={page.data.header} title="Header" path={[j, "data", 'header']} design={design} setDesign={setDesign}/>
            <div>
                {page.data.items.map((blockData, i) => (
                    <Section key={i} data={blockData} title={"Block " + (i+1)} path={[j, "data", 'items', i]} design={design} setDesign={setDesign}/>
                ))}
            </div>
            <Section data={page.data.header} title="Footer" path={[j, "data", 'footer']} design={design} setDesign={setDesign}/>
           </div> 
        ))
    )

}

function Section({data, title, path, design, setDesign}) {
    if (Object.keys(data).filter(key => ["image", "icon"].includes(key)).length === 0) return null;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            marginTop: 20
        }}>
            <div style={{
                fontWeight: 'bold'
            }}>{title}:</div>
            {Object.keys(data).map(key => (
                <>
                    {key == "image" && (<Image key={key} data={data[key]} design={design} setDesign={setDesign} path={[...path, key]} />)}
                    {key == "icon" && (<Icon key={key} data={data[key]} />)}
                </>
            ))}
        </div>
    )
}
function Image({data, design, setDesign, path}) {
    return (
        <div style={{

        }}>
            <ImageWithUploader src={data['src']} design={design} setDesign={setDesign} path={path} />
        </div>
    )
}
function Icon({data}) {
    return (
        <div>
            <div className="editable-icon" dangerouslySetInnerHTML={{__html: data['svg']}} style={{
                width: 50,
                height: 50
            }} />
        </div>
    )
}