import React, { useRef, useState } from 'react';

export default function ImageWithUploader({path, src, design, setDesign}) {
    const fileInputRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(src); // Default image source

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result); // Base64 encoded image
                // Update design
                const newPages = [ ...design.pages ];
                let current = newPages;
                for (let i = 0; i < path.length - 1; i++) {
                    current = current[path[i]];
                }
                current[path[path.length - 1]] = { src: reader.result };
                setDesign({ ...design, pages: newPages });
            };
            reader.readAsDataURL(file);
        }
    };
    
    return (
        <div>
            <img
                src={imageSrc}
                alt="Click to upload"
                onClick={handleImageClick}
                style={{ 
                    cursor: 'pointer', 
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    objectFit: 'cover'
                }}
            />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
            />
        </div>
    );
}
