import React from "react";

const LabeledDiv = ({children, label}) => {
  return (
    <div style={{ position: "relative", border: "1px solid #ccc", borderRadius: "4px", padding: 8 }}>
      <label
        className="MuiInputLabel-root"
        style={{
          position: "absolute",
          top: "-10px",
          left: "-5px",
          background: "white",
          padding: "0 5px",
          color: "#666",
          userSelect: "none",
          
          transform: "scale(0.75)",
        }}
      >
        {label}
      </label>
        {children}
    </div>
  );
};

export default LabeledDiv;
