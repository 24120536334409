import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Terms } from "./terms/terms";
import { Privacy } from "./terms/privacy";
import { Contact } from "./terms/contact";
import { Banner } from "./Banner";
import { BottomBar } from "./BottomBar";
import DesignEditor from "./DesignEditor";
import { Login } from "./Login";
import Home from "./Home";

export function MyRoutes() {
    return (
      <Router basename="/">
        <Routes>
          <Route path="/design/:urlId" element={<DesignEditor />} />
          <Route path="/" element={<Home />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Login />} />

          {["terms", "privacy", "contact"].map((p, i) => (
          <Route
            key={i}
            path={"/" + p}
            element={
              <div>
                <Banner />
                <div style={{ padding: "0 20px 20px 20px", marginBottom: 30 }}>
                  {p === "terms" && <Terms />}
                  {p === "privacy" && <Privacy />}
                  {p === "contact" && <Contact />}
                </div>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                  }}
                >
                  <BottomBar />
                </div>
              </div>
            }
          />
        ))}
      </Routes>
    </Router>
  );
}
