import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Slider, IconButton, Avatar, useMediaQuery } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import YesIcon from "@mui/icons-material/Check";
import NoIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { req } from "./ServerUtils";
import LabeledDiv from "./LabeledDiv";

const EditProfileDialog = ({ userInfo, setUserInfo, onClose, fullScreen, dialog = "profile", setDialog }) => {
  const [name, setName] = useState(userInfo?.name);
  const [email, setEmail] = useState(userInfo?.email);
  const [token, setToken] = useState(userInfo?.tokens.length > 0 ? userInfo?.tokens[0] : "");
  const [fixedImage, setFixedImage] = useState(userInfo?.profile_pic_cropped || "/images/profilepic.jpg");
  const isTablet = useMediaQuery("(max-width: 600px)");
  const [showSubscription, setShowSubscription] = useState(false);

  const [cancelationLoading, setCancelationLoading] = useState(false);
  const [cancelationMessage, setCancelationMessage] = useState(null);

  useEffect(() => {
    setName(userInfo?.name);
    setEmail(userInfo?.email);
    setFixedImage(userInfo?.profile_pic_cropped + "?t=" + new Date().getTime() || "/images/profilepic.jpg");
    setToken(userInfo?.tokens.length > 0 ? userInfo?.tokens[0] : "");
  }, [userInfo]);

  function startCancellation() {
    setDialog("canceling");
  }
  function confirmCancel() {
    setCancelationLoading(true);
    req("/cancel_subscription", "POST").then((res) => {
      setCancelationLoading(false);
      setUserInfo(res);
      if (res.error) {
        setCancelationMessage("Could not cancel subscription. Don't worry. Please email support@makemybrand.ai and we will cancel it immediately.");  
      } else {
        setCancelationMessage("Subscription canceled successfully. We hope to see you again!");
      }
      
    }).catch((error) => {
      setCancelationLoading(false);
      setCancelationMessage("Could not cancel subscription. Don't worry. Please email support@makemybrand.ai and we will cancel it immediately.");
    });
  }
  function abortCancel() {
    setDialog("profile");
  }

  return (
    <Dialog open={Boolean(dialog)} onClose={() => {
      setShowSubscription(false);
      onClose()
    }} fullScreen={fullScreen}>
      <DialogTitle>
        {dialog === "profile" && "Your Profile"}
        {dialog === "tokens" && "Your Token"}
      </DialogTitle>
      <DialogContent>
        {dialog === "profile" && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            width: isTablet ? "90%" : 500,

          }}>
            <ProfilePic userInfo={userInfo} setUserInfo={setUserInfo} fixedImage={fixedImage} setFixedImage={setFixedImage} />
            <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="dense" />
            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="dense" disabled={true} />

            
            {showSubscription ? (
              <LabeledDiv label="Subscription">
                <SubscriptionManagement userInfo={userInfo} startCancellation={startCancellation}  />
                </LabeledDiv>
            ) : (<a href='#' style={{
              fontSize: "0.8rem",
              textAlign: "right"
            }} onClick={() => setShowSubscription(true)}>Manage Subscription</a>)}
          </div>
        )}
        {dialog === "tokens" && <Tokens token={token} setToken={setToken} setUserInfo={setUserInfo} userInfo={userInfo} />}

        {dialog == "canceling" && <Canceling confirmCancel={confirmCancel} abortCancel={abortCancel} cancelationLoading={cancelationLoading} cancelationMessage={cancelationMessage}/>}
      </DialogContent>

      <DialogActions style={{ padding: "0 24px 24px 24px" }}>
        {dialog === "profile" && (
          <>
          </>
        )}
        {dialog === "tokens" && <TokenActions token={token} setToken={setToken} setUserInfo={setUserInfo} userInfo={userInfo} />}
        {dialog === "subscription" && (
          <Button onClick={onClose} variant="contained" color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditProfileDialog;

function ProfilePic({ userInfo, setUserInfo, fixedImage, setFixedImage }) {
  const [image, setImage] = useState(null);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(1.2);

  const fileRef = useRef(null);
  const editorRef = useRef(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleZoomChange = (event, newValue) => {
    setScale(newValue);
  };

  const onCropComplete = (blob) => {
    setImage(null);

    const formData = new FormData();
    formData.append("profile_pic_cropped", blob, "cropped-image.png");
    setLoading(true);
    req("/upload_profile_pic", "POST", formData, false)
      .then((res) => {
        setLoading(false);
        userInfo.profile_pic_cropped = res.profile_pic_url;
        setUserInfo({ ...userInfo });
        setFixedImage(res.profile_pic_url);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {!image && (
        <div style={{ position: "relative" }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <Avatar src={fixedImage} sx={{ width: 100, height: 100 }} />
          {hover && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              <IconButton
                component="label"
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                <AddAPhotoIcon style={{ color: "#fff" }} />
              </IconButton>
            </div>
          )}
          <input ref={fileRef} type="file" accept="image/*" onChange={handleImageChange} hidden />
        </div>
      )}

      {image && (
        <>
          <AvatarEditor ref={editorRef} image={image} width={150} height={150} border={50} borderRadius={75} scale={scale} crossOrigin="anonymous" />
          <Slider value={scale} min={1} max={3} step={0.1} onChange={handleZoomChange} aria-labelledby="zoom-slider" style={{ width: "80%", marginTop: 20 }} />
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "center",
              marginTop: 10,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!loading && (
              <>
                <IconButton
                  size="small"
                  color="success"
                  onClick={() => {
                    if (editorRef.current) {
                      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
                        onCropComplete(blob);
                      }, "image/png");
                    }
                  }}
                >
                  <YesIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setImage(null);
                  }}
                >
                  <NoIcon />
                </IconButton>
              </>
            )}
            {loading && <CircularProgress size={20} />}
          </div>
        </>
      )}
    </div>
  );
}

function cap(str) {
  if (!str || str.length === 0) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function SubscriptionManagement({ userInfo, startCancellation }) {

  return (
    <div style={{ 
      display: "flex", 
      flexDirection: "column", 
      gap: 15,
      padding: 10,
      fontSize: "0.8rem",
    }}>
      <div>
        Current Plan: {cap(userInfo.subscription_type)}
      </div>
      {
        userInfo.subscription_type == "free-trial" && (
          <div>
            Trial ends on {userInfo.trial_ends_on}
          </div>
        )
      }
      <div>
        <span>Used Credits: </span>
        <span>{userInfo.credits}/{userInfo.total_credits}</span>
        {userInfo.refresh_credits_on && (<span>(Refreshes on {userInfo.refresh_credits_on})</span>)}
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        {userInfo.subscription_type != "premium" && (
          <Button variant="outlined" color="primary" style={{
            textTransform: "none"
          }}>
            Upgrade Subscription
          </Button>
        )}
        <a href='#' onClick={startCancellation}>
          Cancel Subscription
        </a>
      </div>
    </div>
  );
}



function Tokens({ token, setToken, setUserInfo, userInfo }) {
  const [tokenCopied, setTokenCopied] = useState(false);

  function genToken() {
    req("/generate_token", "POST").then((res) => {
      userInfo.tokens = [res.token];
      setUserInfo({ ...userInfo });
      setToken(res.token);
    });
  }

  function copyToken() {
    navigator.clipboard.writeText(token);
    setTokenCopied(true);
    setTimeout(() => {
      setTokenCopied(false);
    }, 5000);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        alignItems: "stretch",
        justifyContent: "start",
        width: 500,
      }}
    >
      {tokenCopied && (
        <div
          style={{
            background: "#137e13",
            color: "white",
            padding: 20,
          }}
        >
          Copied token!
        </div>
      )}
      {token.length > 0 && (
        <div
          style={{
            overflowWrap: "anywhere",
            overflow: "wrap",
            border: "1px solid #ccc",
            borderRadius: 5,
            padding: 10,
            cursor: "pointer",
          }}
          onClick={copyToken}
        >
          {token}
        </div>
      )}
      <a href="/videos/token.mp4" target="_blank">
        Integrating with WordPress?
      </a>
    </div>
  );
}

function TokenActions({ token, setToken, setUserInfo, userInfo }) {
  function genToken() {
    req("/generate_token", "POST").then((res) => {
      userInfo.tokens = [res.token];
      setUserInfo({ ...userInfo });
      setToken(res.token);
    });
  }

  function copyToken() {
    navigator.clipboard.writeText(token);
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={genToken}
        style={{
          textTransform: "none",
          backgroundColor: "#ccc",
          color: "#333",
        }}
      >
        Regenerate Token
      </Button>
      <Button
        variant="contained"
        onClick={copyToken}
        style={{
          textTransform: "none",
        }}
      >
        Copy Token
      </Button>
    </>
  );
}

function Canceling({ confirmCancel, abortCancel, cancelationLoading, cancelationMessage }) {
    if (cancelationMessage) {
      return <div style={{
        padding: 10,
        borderRadius: 5
      }}>
        {cancelationMessage}
      </div>
    };
    if (cancelationLoading) {
      return <CircularProgress />
    }
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: 10
      }}>
        <div>Are you sure you want to cancel your subscription?</div>
        <div style={{ display: "flex", gap: 10 }}>
          <Button variant="outlined" color="error" onClick={confirmCancel} style={{
            textTransform: "none"
          }}>
            Yes, cancel
          </Button>
          <Button variant="outlined" color="primary" onClick={abortCancel} style={{
            textTransform: "none",
            flexGrow: 1
          }}>
            No, keep subscription
          </Button>
        </div>
      </div>
    )
 
}