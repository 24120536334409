import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextareaAutosize } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export function ReplacableTexts({design, setDesign}) {
    if (!design) return;

    function onTextChange(path, value) {
        const newPages = [ ...design.pages ];
        let current = newPages;
        for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
        }
        current[path[path.length - 1]] = value;

        setDesign({ ...design, pages: newPages });
    }

    return (
        <div style={{
            marginTop: 20
        }}>
            {design.pages.map((page, j) => (
                <div key={j}>
                        <Section data={page.data.header} title="Header" onTextChange={(k,v) => onTextChange([j, "data", 'header', k], v)}/>
                        <div>
                            {page.data.items.map((blockData, i) => (
                                <Section key={i} data={blockData} title={"Block " + (i+1)} onTextChange={(k,v) => onTextChange([j, "data", 'items', i, k], v)}/>
                            ))}
                        </div>
                        <Section data={page.data.header} title="Footer" onTextChange={(k,v) => onTextChange([j, "data", 'footer', k], v)}/>
                </div> 
            ))}
        </div>
    )

}

function Section({ data, title, onTextChange }) {
    return (
        <Accordion disableGutters square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: '0.9rem', fontWeight: 500 }}>
                {title}
            </AccordionSummary>
            <AccordionDetails style={{ padding: '8px 16px' }}>
                {Object.keys(data).map(key => (
                    ["title", "subtitle", "body"].includes(key) && (
                        <div key={key} style={{ marginBottom: 8 }}>
                            <div style={{ fontSize: '0.8rem', marginBottom: 4 }}>{key[0].toUpperCase() + key.slice(1)}</div>
                            <TextareaAutosize
                                onChange={(e) => onTextChange(key, e.target.value)}
                                style={{ width: '100%', fontSize: '0.8rem', padding: 4 }}
                                value={data[key]}
                            />
                        </div>
                    )
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

