import React, { useState, useEffect, useRef } from 'react';
import { reqPoll } from './ServerUtils';
import { Alert, Box, Button, CircularProgress } from '@mui/material';
import AiSubjectChooser from './AiSubjectChooser';
import { TemplateGallery } from './TemplateSelectorModal';

const Home = () => {
    const [isTablet, setIsTablet] = useState(window.matchMedia('(max-width: 600px)').matches);  
    const [isInputModalOpen, setIsInputModalOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [step, setStep] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [generatedPng, setGeneratedPng] = useState(0);
    const [generatedDesignId, setGeneratedDesignId] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const generate = () => {
        setIsInputModalOpen(true);
    };


  const generateDesign = async () => {
    setError(null);
    setLoading(true);
    setGeneratedPng(null);
    reqPoll('/generate', 'POST', JSON.stringify({
        template_id: selectedTemplate.id,
        subject: selectedSubject,
        url: url
    })).then(res => {
        
        setGeneratedPng(res.url);
        setGeneratedDesignId(res.design_id);
        setLoading(false);
        window.open('/design/' + res.design_id, '_blank');
        
    }).catch(error => {
        console.log('Error generating sample: ' + error.message);
        setError("Error: " + error.message);
        setGeneratedPng(null);
        setLoading(false);
    });
  };

  function download(url) {
    fetch(url + "&a=" + Math.random())
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'makemybrand-image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      })
      .catch(error => console.error('Error downloading the image:', error));
  }
  
  let width = (!isTablet && step == 0) ? 500 : "90%";
  if (step == 2) width = "fit-content";

  return (
    <Box sx={{ p: 2 }}>
      {/* <Modal open={isInputModalOpen} onClose={() => {
        setStep(0);
        setGeneratedPng(null);
        setIsInputModalOpen(false);
      }}> */}
        <Box sx={{ 
          p: 2, 
          backgroundColor: 'white', 
          margin: 'auto', 
          width: width,
          borderRadius: 2, 
          overflow: "scroll",
          border: step == 0 ? '1px solid #ccc' : "none"
        }}>
          {step == 0 && (
            <>
              <AiSubjectChooser url={url} setUrl={setUrl} selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} />
              <Button variant="contained" sx={{ mt: 2 }} fullWidth onClick={() => setStep(1)}>Select Template</Button>
            </>
          )}
          {step == 1 && (
            <>
              <TemplateGallery selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} isOpen={true}/>
              <Button variant="contained" sx={{ mt: 2 }} fullWidth onClick={() => {
                setStep(2); 
                generateDesign();
              }}>Generate</Button>
            </>
          )}
          {step == 2 && (
            <div style={{
              textAlign: 'center',
              overflow: 'scroll',

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              
              {generatedPng && (
                <div style={{ 

                }} > 
                  <div>Your design is ready!</div>

                  <div style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'center',
                    marginBottom: 10
                  }}>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => window.open('/design/' + generatedDesignId)} style={{
                      textTransform: "none"
                    }}>Edit</Button>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => download(generatedPng)} style={{
                      textTransform: "none"
                    }}>Download</Button>
                  </div>
                  <div style={{ 
                    marginTop: 20,
                    border: '1px solid #ccc',
                  }} > 
                    <img src={generatedPng} style={{ height: "100%"  }} /> 
                  </div>
                </div>
              )}
              {error && 
              <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                {error}
              </Alert>}
              {loading && <Loading/>}

            </div>
          )}
        </Box>
      {/* </Modal> */}
    </Box>
  );
};


function Loading({}) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10
      }}>
        <div style={{
        }}>
          Please wait. Your design is being generated.
        </div>
        <CircularProgress />
      </div>
    )
  }


export default Home;


