import React, { useState } from "react";
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AiSubjectAutocomplete from "./AiSubjectAutocomplete";

export default function AiSubjectChooser({ url, setUrl, selectedSubject, setSelectedSubject }) {
  const [value, setValue] = useState("blogUrl");

  const handleTabChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <div>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleTabChange}
        fullWidth
        sx={{
          paddingBottom: 1,
          marginBottom: 2,
        }}
      >
        <ToggleButton value="blogUrl" sx={{ textTransform: "none" }}>
          URL
        </ToggleButton>
        <ToggleButton value="selectTopic" sx={{ textTransform: "none" }}>
          Topic
        </ToggleButton>
      </ToggleButtonGroup>

      <div>
        {value === "blogUrl" && (
          <div>
            <TextField label="Paste a URL" fullWidth value={url} onChange={(e) => setUrl(e.target.value)} />
          </div>
        )}
        {value === "selectTopic" && (
          <div>
            <AiSubjectAutocomplete setSelectedSubject={setSelectedSubject} />
          </div>
        )}
      </div>
    </div>
  );
}
